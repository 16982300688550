import { mapActions, mapMutations } from 'vuex';

//---NOTE: copy stupid structure from old wordpress site=(
export default {
  name: 'ProductCategorySelect',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    slug: {
      type: String,
      default: ''
    }
  },
  emits: ['open'],
  data() {
    return {
      open: false,
      selectedCategory: {},
      isChecked: false
    };
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this._getChecked();
        }
      }
    }
  },
  computed: {},
  created() {},
  methods: {
    ...mapActions({}),
    ...mapMutations({}),
    _getChecked() {
      let status = false;
      let { filters } = this.$route.query;
      if (filters) {
        let categoryFind = filters.split('|').find((categoryString) => categoryString.indexOf(this.slug) > -1);

        if (categoryFind) {
          let idsArray = categoryFind.slice(categoryFind.indexOf('[') + 1, categoryFind.indexOf(']')).split('-');
          let filterId = this.data.id.toString();
          status = idsArray.indexOf(filterId) > -1;
          if (status) {
            this.$emit('open');
          }
        }
      }
      this.isChecked = status;
    },
    onSelectCategory() {
      let editQuery = { ...this.$route.query };
      let { filters, ...otherQueryAttrs } = editQuery;

      let { id } = this.data;

      if (filters) {
        if (filters.indexOf(this.slug) > -1) {
          let editFilters = filters.split('|').reduce((acc, categoryString) => {
            let newVal = categoryString;
            let categorySlug = categoryString.split('[')[0];

            if (categorySlug === this.slug) {
              let idsArray = categoryString
                .slice(categoryString.indexOf('[') + 1, categoryString.indexOf(']'))
                .split('-');

              let filterId = id.toString();
              idsArray =
                idsArray.indexOf(filterId) > -1 ? idsArray.filter((itemId) => itemId !== filterId) : [...idsArray, id];

              newVal = idsArray.length ? `${categorySlug}[${idsArray.join('-')}]` : '';
            }
            return newVal.length ? (acc.length ? `${acc}|${newVal}` : newVal) : acc;
          }, '');
          editQuery = editFilters.length ? { ...editQuery, filters: editFilters } : otherQueryAttrs;
        } else {
          editQuery = { ...editQuery, filters: `${filters}|${this.slug}[${id}]` };
        }
      } else {
        editQuery = { ...editQuery, filters: `${this.slug}[${id}]` };
      }

      let { name, params } = this.$route;
      this.$router
        .replace({
          name,
          query: editQuery,
          params
        })
        .catch(() => {});
    }
  }
};
