import { VueSlideToggle } from 'vue-slide-toggle';
import ProductsCategorySelect from './ProductCategorySelect/index.vue';

export default {
  name: 'ProductsCategoryFilter',

  props: {
    filter: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isOpen: false,
      renew: []
    };
  },
  components: {
    VueSlideToggle,
    ProductsCategorySelect
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    onOpenCategory() {
      if (!this.isOpen) {
        this.isOpen = true;
      }
    },
  }
};
