import { mapActions } from 'vuex';

import MainSelect from '@/components/@core/MainSelect/index.vue';
import { PRODUCTS_ORDER_KEY, PRODUCTS_VIEW_TYPE } from '@/helpers';
import { MEDIA } from '@/helpers/variables';
import IconList from '@/components/icons/IconList.vue';
import IconGrid from '@/components/icons/IconGrid.vue';

export default {
  name: 'ProductsSortSelect',
  components: {
    IconGrid,
    IconList,
    MainSelect
  },
  props: {
    viewType: {
      type: String,
      default: PRODUCTS_VIEW_TYPE.GRID
    },
    sortTypeDefault: {
      type: [String, null],
      default: null
    }
  },
  emits: ['change-view'],
  data() {
    return {
      MEDIA,
      PRODUCTS_VIEW_TYPE,
      sortType: PRODUCTS_ORDER_KEY.BESTSELLERS,
      list: [
        {
          id: 0,
          key: PRODUCTS_ORDER_KEY.PRICE_DESC,
          name: this.$t('sortTypes.priceDesc')
        },
        {
          id: 1,
          key: PRODUCTS_ORDER_KEY.PRICE_ASC,
          name: this.$t('sortTypes.priceAsc')
        },
        {
          id: 2,
          key: PRODUCTS_ORDER_KEY.WITH_PROMOTIONS,
          name: this.$t('sortTypes.withPromotions')
        },
        {
          id: 3,
          key: PRODUCTS_ORDER_KEY.BESTSELLERS,
          name: this.$t('sortTypes.bestsellers')
        },
        {
          id: 4,
          key: PRODUCTS_ORDER_KEY.NEWEST,
          name: this.$t('sortTypes.newest')
        }
      ],
      defaultSortKey: null,
      currentSort: null
    };
  },

  created() {
    let { orderby } = this.$route.query;
    this.sortType = orderby || this.sortTypeDefault || PRODUCTS_ORDER_KEY.BESTSELLERS;
    this.defaultSortKey = this.sortTypeDefault || PRODUCTS_ORDER_KEY.BESTSELLERS;
    this.currentSort = this.list.find(({ key }) => key === this.sortType);
  },
  computed: {},
  methods: {
    ...mapActions({}),
    onChangeProductsView(type) {
      this.$emit('change-view', type);
    },
    onChangeSort(sort) {
      this.currentSort = sort;
      let { name, query, params } = this.$route;
      let editedQuery = { ...query };

      if (sort.key === this.defaultSortKey) {
        delete editedQuery.orderby;
      } else {
        editedQuery = { ...editedQuery, orderby: sort.key };
      }
      this.$router.push({ name, query: editedQuery, params }).catch(() => {});
    }
  }
};
